function absoluteUri(uri) {
    const a = document.createElement('a');
    a.href = uri;
    return a.href;
}

export const MANIFEST = {
    name: 'Cockpit',
    short_name: 'Cockpit',
    lang: 'en-US',
    description:
        'Onboarding, work scheduling, time tracking, holidays and more',
    scope: absoluteUri('/'),
    id: absoluteUri('/employee_auth/shifts/'),
    start_url: absoluteUri('/employee_auth'),
    display: 'standalone',
    background_color: '#083b66',
    theme_color: '#083b66',
    icons: [
        {
            src: absoluteUri('/icon.svg'),
            sizes: '48x48 72x72 96x96 128x128 256x256',
            type: 'image/svg+xml',
            purpose: 'any',
        },
        ...[192, 512].map(size => ({
            src: absoluteUri(`/icon${size}.png`),
            sizes: `${size}x${size}`,
            type: 'image/png',
            purpose: 'any',
        })),
    ],
    screenshots: [
        ['Availabilities', 'screenshot1.png'],
        ['Onboarding', 'screenshot2.png'],
        ['Checking in and out', 'screenshot3.png'],
    ].map(([label, filename]) => ({
        src: absoluteUri(`/img/screenshots/${filename}`),
        sizes: '1440x2960',
        type: 'image/png',
        form_factor: 'narrow',
        label,
    })),
};
