import moment from 'moment';
import { locale } from '../i18n';
import { computed } from 'vue';
import now from './now';

const relativeTimeFormat = computed(
    () => new Intl.RelativeTimeFormat(locale.value)
);

export default function formatRelativeTime(date) {
    if (date == null) {
        return '';
    }

    const days = moment(date)
        .startOf('day')
        .diff(moment(now).startOf('day'), 'days');

    return relativeTimeFormat.value.format(days, 'day');
}
