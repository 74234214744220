import { ref, computed, watch } from 'vue';

const installPromptEvent = ref();

window.addEventListener('beforeinstallprompt', event => {
    installPromptEvent.value = event;
});

export const isAvailable = computed(() => !!installPromptEvent.value);

export const prompt = async () => {
    if (!isAvailable.value) {
        throw new Error('Install prompt not available');
    }
    const result = await installPromptEvent.value.prompt();
    console.log('Install prompt result:', result);
    installPromptEvent.value = null;
    return result;
};

watch(isAvailable, (value, prev) => {
    if (value || prev) {
        console.log('Install prompt availability changed:', value);
    }
});
