import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import { store } from './store/store.js';
import apiEndPoints from './apiEndPoints.js';
import asyncComputed from './asyncComputed.js';
import methods from './helpers/mixins.js';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { SessionExpiredError } from './unauthorizedRedirect';
import VueSignaturePad from 'vue-signature-pad';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import PortalVue from 'portal-vue';
import { startAnalytics } from './analytics.js';
import ObserveSizeDirective from './ObserveSizeDirective.js';
import { I18nPlugin } from './i18n.js';
import './moment.js';
import { installTrackInputMixins } from './trackInput.js';
import './title.js';
import './statsig.js';

startAnalytics();

if (
    process.env.VUE_APP_SENTRY_ENVIRONMENT === 'production' &&
    !isSensitiveUrl()
) {
    Sentry.init({
        Vue,
        dsn: 'https://efce6564ec7147d6ae65acfd5c985161@o912543.ingest.sentry.io/5855880',
        integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
        tracesSampleRate: 0.05,
        environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
        trackComponents: true,
        logErrors: true,
        tunnel: process.env.VUE_APP_API_URL + '/sentry/',
        replaySessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        beforeSend(event, hint) {
            if (
                isApplicationError(hint) ||
                hint.originalException instanceof SessionExpiredError ||
                (isRouterError(hint) && isRedirectToLoginPage(hint))
            ) {
                return null;
            }
            return event;
        },
    });
}

if (new URL(location.href).searchParams.has('TEST_SENTRY')) {
    throw new Error('Testing Sentry');
}

function isSensitiveUrl() {
    return /\/sign_document\//.test(window.location);
}

function isRedirectToLoginPage(hint) {
    return ['userLogin', 'employeeLogin'].includes(
        hint.originalException.to.name
    );
}

function isRouterError(hint) {
    return hint.originalException?._isRouter;
}

function isApplicationError(hint) {
    return hint.originalException?.graphQLErrors?.some(
        error => error.extensions?.is_application_error
    );
}

Vue.config.productionTip = false;

installTrackInputMixins();
Vue.mixin(asyncComputed);
Vue.mixin({ methods: apiEndPoints });
Vue.mixin(methods);
Vue.use(VueSignaturePad);
Vue.use(VueTelInputVuetify, { vuetify });
Vue.use(PortalVue);
Vue.use(ObserveSizeDirective);
Vue.use(I18nPlugin);

new Vue({
    vuetify,
    store,
    router,
    render: h => h(App),
}).$mount('#app');

if (
    !/^localhost$/.test(location.hostname) &&
    'serviceWorker' in window.navigator
) {
    window.navigator.serviceWorker.register('/service-worker.js');
}
