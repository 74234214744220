<template>
    <div :class="$style.container">
        <div :class="$style.description">
            {{ __('Follow these steps to install the app on your iPhone.') }}
        </div>
        <div :class="$style.step">
            <div :class="$style.stepNumber">1</div>
            <div :class="$style.stepDescription">
                <I18n
                    :message="
                        __('Tap the %1 button at the bottom of the screen')
                    "
                >
                    <template #1>
                        <img src="/img/ios-share.png" />
                    </template>
                </I18n>
            </div>
        </div>
        <div :class="$style.step">
            <div :class="$style.stepNumber">2</div>
            <div :class="$style.stepDescription">
                {{ __('Select %1 and tap %2', actionLabel, addLabel) }}
            </div>
        </div>
        <div :class="$style.videoContainer">
            <video
                src="/img/install-pwa.mp4"
                autoplay
                muted
                loop
                playsinline
                disablepictureinpicture
                @loadedmetadata="loadedMetadata = true"
                :class="loadedMetadata ? $style.loadedMetadata : ''"
            />
        </div>
        <div :class="$style.actions">
            <v-btn @click="$emit('dismiss')">
                {{ __('Continue without installing') }}
            </v-btn>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        loadedMetadata: false,
    }),
    computed: {
        actionLabel() {
            if (navigator.language.match(/^nl/)) {
                return 'Zet op beginscherm';
            }
            return 'Add to Home Screen';
        },
        addLabel() {
            if (navigator.language.match(/^nl/)) {
                return 'Voeg toe';
            }
            return 'Add';
        },
    },
};
</script>
<style module>
.container {
    display: flex;
    flex-direction: column;
    height: calc(100dvh - 80px);
}
.step {
    display: flex;
    margin-bottom: 1rem;
    align-items: flex-start;
}
.description {
    margin-bottom: 1rem;
}
.stepNumber {
    font-size: 1.2rem;
    font-weight: 600;
    background-color: #083b66;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-right: 0.5rem;
    flex-shrink: 0;
}
.stepDescription {
    margin-top: 0.2rem;
}
.stepDescription img {
    height: 1.5em;
    vertical-align: middle;
}
.videoContainer {
    position: relative;
    flex: 1;
    margin-top: 20px;
    margin-bottom: 40px;
}
.videoContainer > video {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}
.loadedMetadata {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}
.actions {
    display: flex;
    justify-content: center;
}
</style>
