import { ref, readonly } from 'vue';
import moment from 'moment';

const now = ref(moment());

function updateNow() {
    const value = moment();
    now.value = value;
    setTimeout(updateNow, 1000 - value.millisecond());
}

updateNow();

export default readonly(now);
