import { computed, watchEffect } from 'vue';

const title = computed(() => {
    let result = 'Eyetelligence Cockpit';
    if (location.hostname === 'localhost') {
        result = `(local) ${result}`;
    }
    return result;
});

watchEffect(() => {
    document.title = title.value;
});
