import { __ } from '../i18n.js';
import {
    parseMessage,
    Text,
    Placeholder,
    ParametrizedSlot,
} from '../i18n-parser.js';

export default {
    functional: true,
    props: {
        message: String,
    },
    render: (createElement, ctx) => {
        const slots = getSlotsArray(ctx.scopedSlots);

        const tokens = parseMessage(String(ctx.props.message));

        const render = tokens => {
            const children = [];

            for (const token of tokens) {
                if (token instanceof Text) {
                    children.push(token.value);
                } else if (token instanceof Placeholder) {
                    children.push(slots[token.idx - 1]());
                } else if (token instanceof ParametrizedSlot) {
                    children.push(
                        slots[token.idx - 1]({
                            content: {
                                render: h =>
                                    h(
                                        'div',
                                        { style: { display: 'inline' } },
                                        render(token.tokens)
                                    ),
                            },
                        })
                    );
                }
            }

            return children;
        };

        return createElement(
            'div',
            { style: { display: 'inline' } },
            render(tokens)
        );
    },
};

function getSlotsArray(slots, createElement) {
    const slotKeys = Object.keys(slots);

    if (slotKeys.length === 1 && slotKeys[0] === 'default') {
        return [slots.default];
    }

    const result = [];

    for (let i = 1; i <= slotKeys.length; i++) {
        if (!(i in slots)) {
            console.error(
                `<I18n> expects numeric slot names, starting from 1. Missing ${i}.`
            );
            result.push(({ content }) =>
                content === undefined
                    ? createElement('span', `%${i}`)
                    : createElement(content)
            );
            continue;
        }
        result.push(slots[i]);
    }

    return result;
}
