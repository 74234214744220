<template>
    <div
        :class="$style.overlay"
        v-if="enablePwaInstallPrompt"
        @dismiss="dismissed = true"
        @touchmove.prevent
        @mousewheel.prevent
    >
        <div :class="$style.container">
            <IosInstallPrompt
                v-if="showIosInstallPrompt"
                @dismiss="showIosInstallPrompt = false"
            />
            <template v-else>
                <div :class="$style.description">
                    {{ __('Install the app to get the best experience.') }}
                </div>
                <div :class="$style.actions">
                    <v-btn @click="dismiss">
                        {{ __('No thanks') }}
                    </v-btn>
                    <v-btn @click="install" color="primary">
                        {{ __('Install') }}
                    </v-btn>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import { prompt, isAvailable } from '../installPrompt';
import { isPhone } from '../helpers/isPhone';
const dismissedKey = 'pwaInstallPromptDismissed';

export default {
    data: () => ({
        dismissed: !!sessionStorage.getItem(dismissedKey),
        showIosInstallPrompt: false,
    }),
    methods: {
        async install() {
            if (isAvailable.value && !this.testIosInstallPrompt) {
                await prompt();
                this.dismiss();
            } else if (this.displayIosInstallPrompt) {
                this.showIosInstallPrompt = true;
            }
        },
        dismiss() {
            this.showIosInstallPrompt = false;
            this.dismissed = true;
            sessionStorage.setItem(dismissedKey, 'yes');
        },
    },
    computed: {
        enablePwaInstallPrompt() {
            return (
                !this.dismissed &&
                isPhone() &&
                (isAvailable.value ||
                    this.displayIosInstallPrompt ||
                    document.cookie.match(/TEST_PWA_INSTALL_PROMPT/))
            );
        },
        displayIosInstallPrompt() {
            return (
                this.testIosInstallPrompt ||
                (/\b(iPhone|iPad|iPod)\b/.test(navigator.userAgent) &&
                    /\bAppleWebKit\b/.test(navigator.userAgent) &&
                    !/\b[A-Za-z]+iOS\//.test(navigator.userAgent) &&
                    'standalone' in navigator &&
                    !navigator.standalone)
            );
        },
        testIosInstallPrompt() {
            return /\bTEST_IOS_INSTALL_PROMPT\b/.test(document.cookie);
        },
    },
    watch: {
        dismissed(value) {
            if (value) {
                sessionStorage.setItem(dismissedKey, 'yes');
            }
        },
    },
};
</script>
<style module>
.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-end;
}
.container {
    display: flex;
    background: white;
    flex-direction: column;
    gap: 24px;
    border-top: 1px solid #ccc;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
    padding: 24px;
    width: 100%;
}
.description {
    text-align: center;
}
.actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
